import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import {useNavigate } from "react-router-dom";


const LoginOrReturnHome = () => {
  const {
    isAuthenticated,
    loginWithRedirect
  } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect()
    }
    else {
      navigate("/")
    }
  });


  return (
    <React.Fragment>
    </React.Fragment>
  );
};

export default LoginOrReturnHome;
