import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Key } from '@mui/icons-material';
import { Grid, Typography, Box, Container, TextField, Button, Link } from '@mui/material';
import { theme } from '../../utils/theme';

const AdminLogin = () => {
  const { loginWithRedirect } = useAuth0();
  const [username, setUsername] = useState('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    loginWithRedirect({
      login_hint: username,
      redirectUri: window.location.origin + '/admin/campaigns'
    });
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        padding: { xs: '10px', sm: '15px', md: '20px' },
        paddingTop: { xs: '80px', sm: '100px', md: '120px' },
        paddingBottom: { xs: '30px', sm: '40px', md: '50px' },
      }}
    >
      <Grid container spacing={3} alignItems="stretch" direction={{ xs: 'column', sm: 'row' }}>
        <Grid item xs={12} sm={12} md={6}>
          <Box>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: '40px', sm: '48px', md: '64px' },
                fontWeight: 400,
                marginBottom: '20px',
                color: theme.palette.common.navy,
              }}
            >
              Welcome to DemLaunch Admin
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: { xs: '10px', sm: '11px', md: '12px' },
                fontWeight: 700,
                color: theme.palette.common.navy,
                marginBottom: '10px',
                letterSpacing: '1px',
              }}
            >
              FOR STATE PARTIES
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: { xs: '16px', sm: '17px', md: '18px' },
                color: '#475467',
                maxWidth: '500px',
                lineHeight: 1.5,
              }}
            >
              Here you can see all of your campaigns at a glance, take action on the next step, and learn more about how best to get the most out of DemLaunch.
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <Box sx={{ maxWidth: '375px', margin: '0 auto' }}>
            <Typography
              variant="h5"
              sx={{
                marginBottom: '10px',
                fontWeight: 500,
                color: '#111927',
              }}
            >
              Login
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="Username"
                name="username"
                variant="outlined"
                margin="normal"
                value={username}
                onChange={handleInputChange}
              />
              <Button
                type="submit"
                color="primary"
                size="medium"
                variant="contained"
                endIcon={<Key />}
                sx={{ mb: 2, pl: 4, pr: 4, mt: '12px', color: '#FFFFFF', fontSize: '16px' }}
              >
                Login
              </Button>
            </form>
            <Typography
              variant="body2"
              sx={{
                marginTop: '10px',
                textAlign: 'left',
                color: theme.palette.common.navy,
                fontWeight: 300,
              }}
            >
              Need help logging in?{' '}
              <Link href="#" sx={{ color: '#344152', fontWeight: 200 }}>
                Email the stac team.
              </Link>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default AdminLogin;