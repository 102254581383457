import _ from "lodash";
import { Typography, Box, Grid, useMediaQuery } from "@mui/material";
import LinkifyText from "../common/LinkifyText";
import { BuyerDetail, BuyerPerson } from "../../interfaces";
import { theme } from "../../utils/theme";


const NOT_APPLICABLE  = 'N/A'

interface AboutProps {
  buyerDetails: BuyerDetail[]
  buyerPersons: BuyerPerson[]
}
const DetailsAboutSection = (props: AboutProps) => {
  const { buyerDetails = [], buyerPersons } = props;
  const isMediumBreakpoint = useMediaQuery(theme.breakpoints.down('md'));

  const candidate = _.find(buyerPersons, (person) => person.role && person.role.name === 'Candidate');

  const fullName = candidate ? `${candidate.person?.firstName || ''} ${candidate.person?.lastName || ''}` : NOT_APPLICABLE;
  const email = candidate?.person?.email || NOT_APPLICABLE;
  const phone = candidate?.person?.phone || NOT_APPLICABLE;

  const keysToExtract = ['Office', 'website', 'ElectionDate', 'ContactAddress', 'ContactCity', 'ContactZip', 'Website', 'Comments'];

  const extractedFields = buyerDetails.reduce((acc: { [key: string]: string }, detail: BuyerDetail) => {
    if (detail.key && keysToExtract.includes(detail.key) && typeof detail.key === 'string') {
      acc[detail.key] = detail.value !== undefined ? detail.value : NOT_APPLICABLE;
    }
    return acc;
  }, {});

  const aboutSectionFields = {
    'Full Name': fullName,
    'Email': email,
    'Phone': phone,
    'Office': extractedFields['Office'] || NOT_APPLICABLE,
    'Election Date': extractedFields['ElectionDate']
      ? new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }).format(new Date(extractedFields['ElectionDate']))
      : NOT_APPLICABLE,
    'Address': `${extractedFields['ContactAddress'] || NOT_APPLICABLE}, ${extractedFields['ContactCity'] || NOT_APPLICABLE}, ${extractedFields['ContactZip'] || NOT_APPLICABLE}`
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        maxWidth: '455px',
        ...(isMediumBreakpoint && {
          margin: '16px',
          padding: '16px',
        }),
      }}
    >
      {Object.entries(aboutSectionFields).map(([label, value]) => (
        <Grid item xs={12} key={label}>
          <Typography
            variant='overline'
            color='textSecondary'
            sx={{ margin: 0, padding: 0, lineHeight: '1.1', letterSpacing: 'normal' }}
          >
            {label.toUpperCase()}
          </Typography>
          <Typography variant="body1" sx={{ margin: 0 }}>
            {value}
          </Typography>
        </Grid>
      ))}

      <Grid item xs={12}>
        <Typography
          variant="overline"
          color="textSecondary"
          sx={{ margin: 0, padding: 0, lineHeight: '1.1', letterSpacing: 'normal' }}
        >
          WEBSITE
        </Typography>
        <Box sx={{ display: 'block' }}>
          <LinkifyText
            text={extractedFields['Website'] || NOT_APPLICABLE}
            variant='body1'
            sx={{ margin: 0 }}
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Typography
          variant='overline'
          color='textSecondary'
          sx={{ margin: 0, padding: 0, lineHeight: '1.1', letterSpacing: 'normal' }}
        >
          FURTHER DETAILS
        </Typography>
        <Box sx={{ display: 'block' }}>
          {extractedFields['Comments'] || NOT_APPLICABLE}
        </Box>
      </Grid>
    </Grid>
  );
};

export default DetailsAboutSection;
