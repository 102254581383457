import { Backdrop, Box, Grid, IconButton, Paper, Popper, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { User } from '@auth0/auth0-react';

import { useGlobalStore } from "../../store";

import { Good, GoodOption, GoodUsage, GoodVisibility, OptionTemplate, Seller } from '../../interfaces';
import ToolPreviewCard from './ToolPreviewCard';
import { useEffect, useRef, useState } from 'react';
import { useLambdas } from '../../utils/lambdas';
import { bindPopper, usePopupState } from 'material-ui-popup-state/hooks';
import { ArrowBack, ArrowUpward, CancelOutlined } from '@mui/icons-material';

interface AvailableToolsProps {
  hasRequestedTools?: boolean
  seller?: Seller
  user?: User
  buyerId?: number
  optionTemplate: OptionTemplate
  isShowSeller?: boolean
  requestedGoodIds?: number[]
  canceledGoodIds?: number[]
  isRequestable?: boolean
  refetchPage?: Function
  changeTab?: Function
  setRequeryPotentialGoods?: Function
}

interface PotentialGood {
  good: Good,
  usage: GoodVisibility
  message?: string
}

function getGoodUsages(
  potentialGoods: PotentialGood[], 
  goodOptions: GoodOption[],
  requestedGoodIds: number[] | undefined,
  canceledGoodIds: number[] | undefined
  ) {
  let goodUsages:GoodUsage[] = [];
  for (let potentialGood of potentialGoods) {
    const goodId = potentialGood.good.id;
    if (requestedGoodIds && requestedGoodIds.includes(goodId)) {
      if (!canceledGoodIds || !canceledGoodIds.length || !canceledGoodIds.includes(goodId)) {
        continue
      }
    }
    for (let goodOption of goodOptions) {
      if (goodOption.good.id === goodId) {
        goodUsages.push({
          goodOption: goodOption,
          usage: potentialGood.usage,
          message: potentialGood.message
        })
      }
    }
  }
  return goodUsages
}

export const AvailableTools = (props: AvailableToolsProps) => {
  const { seller, setRequeryPotentialGoods, requestedGoodIds, canceledGoodIds, user, isRequestable, refetchPage, hasRequestedTools, changeTab, optionTemplate } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const toolRef = useRef<HTMLButtonElement>(null);

  const theme = useTheme();
  const { person } = useGlobalStore();
  const popupState = usePopupState({ variant: 'popper', popupId: 'simple-popover' })

  useEffect(() => {
    if (!hasRequestedTools && toolRef && toolRef.current) {
      popupState.open(toolRef.current)
      setAnchorEl(toolRef.current)
    }
  /** 
   * JR 2024-01-05: this is necessary to force this to happen only one time on component mount.
   * No dependency array or including popupState in the dependency array leads to infinite render loops.
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (anchorEl && toolRef && toolRef.current) {
      toolRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start"
      })
    }
  }, [anchorEl]);

  const onClose = () => {
    setAnchorEl(null);
    popupState.close();
  };

  const id = Boolean(anchorEl) ? 'simple-popover' : undefined;

  const [
    { data: potentialGoodsForBuyer = { goods: [] },
      loading: arePotentialGoodsLoading
    }, getBuyerPotentialGoods] = useLambdas(
      "getBuyerPotentialGoods",
      props.user,
      {
        templateId: optionTemplate?.id || 0,
        buyerId: props.buyerId
      }, 
      false
  )

  useEffect(() => {
    if (setRequeryPotentialGoods) {
      setRequeryPotentialGoods(() => getBuyerPotentialGoods)
    }

  }, [setRequeryPotentialGoods, getBuyerPotentialGoods])

  let goodUsages:GoodUsage[] = [];
  if (!arePotentialGoodsLoading && optionTemplate?.goodOptions && potentialGoodsForBuyer) {
    goodUsages = getGoodUsages(
      potentialGoodsForBuyer.goods, 
      optionTemplate.goodOptions, 
      requestedGoodIds, 
      canceledGoodIds)
    }

  const isMediumBreakpoint = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{ backgroundColor: theme.palette.common.yellowBackground, pb: 4, pt:2, pr: 5, pl: 5}}>
      <Typography variant={'h5'} sx={{ paddingBottom: 2 }}>
        Available Tools
        { props.isShowSeller && (" - " + seller?.name) }
      </Typography>
      <Backdrop 
        open={popupState.isOpen} 
        onClick={onClose}
      />
      <Grid container spacing={2}>
      { goodUsages?.length === 0 ? <ToolPreviewCard person={person} /> :
          goodUsages?.map((goodUsage, index) => (
          <ToolPreviewCard
            key={goodUsage.goodOption.id}
            person={person}
            sellerId={seller?.id}
            user={user}
            goodOption={goodUsage.goodOption}
            isRequestable={isRequestable}
            refetchPage={refetchPage}
            onClose={onClose}
            changeTab={changeTab}
            visibilityMessage={goodUsage.message}
            usage={goodUsage.usage}
            {... (index === 0) ? {ref: toolRef} : {} }
            {... popupState.isOpen ? { sx: {zIndex: (theme) => theme.zIndex.drawer + 2 }} : {}}
            />
          ))}
      </Grid>
      { anchorEl && <Popper
        id={id}
        sx={{ 
          zIndex: (theme) => theme.zIndex.drawer + 3,
          width: '50%'
        }}
        disablePortal={false}
        {...bindPopper(popupState)} 
        placement={isMediumBreakpoint ? 'bottom-start' : 'right-start'}
        modifiers={[
          {
            name: 'flip',
            enabled: true,
            options: {
              rootBoundary: 'document',
              padding: 8,
            },
          },
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              rootBoundary: 'document',
              padding: 8,
            }, 
          }, 
          {
            name: 'arrow',
            enabled: true,
            options: {
              element: anchorEl,
            },
          },
        ]}
      >
        <Paper
          sx={{
            backgroundColor: theme.palette.common.navy,
            color: 'white',
            borderRadius: '4px'
          }}
        >
          <Stack>
            <Stack direction="row" sx={{display: 'flex', alignItems: 'center', justifyContent:"space-between"}}>
              <IconButton aria-label='left-arrow' sx={{ color: 'white'}}>
                {isMediumBreakpoint ? <ArrowUpward/> : <ArrowBack/> }
              </IconButton>
              <IconButton aria-label='close' onClick={onClose} sx={{ color: 'white'}}>
                <CancelOutlined/>
              </IconButton>
            </Stack>
            <Typography variant='h6' sx={{ p: 1 }}>Select a tool to begin</Typography>
            <Typography sx={{ p: 1 }} >Get started today by selecting a tool{ seller?.name ? ` from ${seller.name}` : ''}!</Typography>
          </Stack>
        </Paper>
      </Popper>}
    </Box>
  )
}