import { Radio, RadioGroup, FormLabel, FormControlLabel, FormControl, TextField } from '@mui/material';
import { BuyerOptionProps, isGroupBuyerOptionValues } from '.';

import React, { useState } from 'react';
import LinkifyText from '../../common/LinkifyText';


export default function RadioGroupControl(props: BuyerOptionProps) {
  const radioOptionVals = isGroupBuyerOptionValues(props.value) ? props.value : undefined;
  const vals = radioOptionVals ? radioOptionVals.options : [''];

  const [selectedValue, setSelectedValue] = useState(props.currentValue || '');

  const handleDetailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onDetailChange) {
      props.onDetailChange(props.optionKey, e.target.value, props.isEditMode ? props.buyerDetailId : props.id);
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(e.target.value);
    handleDetailChange(e);
  }

  return (
    <FormControl required={props.isRequired} disabled={props.isDisabled}>
      <FormLabel sx={{ pb: 1 }}>
        <LinkifyText text={props.label} />
      </FormLabel>
    <RadioGroup
      value={selectedValue}
      onChange={handleChange}>
        {
          vals.map(value => {
            return(
              <FormControlLabel
                key={value}
                value={value}
                disabled={props.isDisabled}
                control={<Radio required={props.isRequired}/>}
                label={value === "Other" ? <TextField
                  variant="standard" label={value} onChange={handleDetailChange}
                  required={props.isRequired && selectedValue === "Other"}
                /> : <LinkifyText text={value} />}
              />
            )
          })
        }
    </RadioGroup>
  </FormControl>
  );
}
