
import {
  Button,
  Grid,
  Typography
} from '@mui/material';
import { useState } from 'react';
import footerLogo from '../mallory_logo_footer.png';
import { EmbedDialog } from './common';

const Footer = () => {
  const [openToolSuggestion, setOpenToolSuggestion] = useState(false);

  return (
    <div className='footer-bar'>
      <Grid container width={'100%'}>
        <Grid item sx={{pl: 1}} xs={12}>
          <Typography variant="caption" 
            sx={{ justifyContent: "space-between", boxShadow: 1 }}
          >
            Created by the team at 
          </Typography>
        </Grid>
        <Grid item sx={{ pl: 1, pb: 2 }} sm={12} md={5} lg={8} width={'100%'}>
          <img src={footerLogo} alt="logo" className="footer-logo" />
        </Grid>
        <Grid item sm={12} md={7} lg={4} width={'100%'}>
          <Button 
            variant="text" 
            href="https://index.staclabs.io" 
            target="_blank"
            sx={{ color: 'white'}}
          >
            <Typography variant="caption">Tech Index</Typography>
          </Button>
          <Button
            variant="text"
            onClick={() => setOpenToolSuggestion(true)}
            sx={{ color: 'white' }}
          >
            <Typography variant="caption">Have a tool suggestion?</Typography>
          </Button>
        </Grid>
      </Grid>
      <EmbedDialog
        link="https://airtable.com/embed/appBJ4aeymE2Mt7yC/pagUYp3KZWAyy2iiG/form"
        title="tool-suggestion"
        open={openToolSuggestion}
        setOpen={setOpenToolSuggestion}
      />
    </div>
  )
}

export default Footer;