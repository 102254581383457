import { Grid, Typography, TextField } from '@mui/material';

interface CampaignHeaderProps {
  searchQuery?: string | null
  setSearchQuery: Function
}

const CampaignHeader = ( props : CampaignHeaderProps) => {
  const { searchQuery, setSearchQuery } = props

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  return (

    <Grid container spacing={3} alignItems="center" sx={{ paddingBottom: '40px' }}>
      <Grid item xs={6}>
        <Typography variant="h4">All campaigns</Typography>
      </Grid>

      <Grid item xs={6} sx={{ textAlign: 'right' }}>
        <TextField
          variant="outlined"
          placeholder="Search"
          value={searchQuery}
          onChange={handleSearchChange}
          sx={{
            width: {
              xs: '100%',
              sm: '200px',
              xl: '300px',
            },
          }}
        />
      </Grid>
    </Grid>
  )

}

export default CampaignHeader