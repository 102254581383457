import parsePhoneNumber from 'libphonenumber-js';


export function validatePhone(phone: string): string | null {
    const phoneNumber = parsePhoneNumber(phone, 'US');

    if (phoneNumber && phoneNumber.isValid()) {
        return phoneNumber.number
    }
    return null
};