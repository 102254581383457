import { v4 as uuid } from 'uuid';

export interface BaseModel {
  id: number
  name: string
}

export interface IDInput {
  id: number
}

export interface Account {
  email: string
  authUserId?: string
  isActive?: boolean
  person?: Person
}

export interface BuyerDetail {
  id: number
  option: BuyerOption
  value: string
  key?: string
  label?: string
  externalId?: ExternalId
}

export interface BuyerOption {
  id: number
  key: string
  label: string
  description: string
  value: PersonBuyerOptionValues | GroupBuyerOptionValues
  detailType: BuyerDetailType
  inputType: string
  uiElement: string
  uiOrder: number
  isRequired: boolean
  districtGroups?: DistrictGroup[]
}

export type PersonOptionValueKey = keyof { 
  firstName: string,
  lastName: string,
  middleName: string,
  email: string,
  phone: string,
  role: string,
}

export interface PersonBuyerOptionValue {
  order: number
  label: string
  key: PersonOptionValueKey
  is_required: boolean
}

export interface PersonBuyerOptionValues {
  fields: PersonBuyerOptionValue[]
}

export interface GroupBuyerOptionValues {
  options: string []
  default?: string
}

export interface ExternalId {
  externalId: string
  externalIdType: string
}

export interface Good extends BaseModel {
  id: number
  description: string
  logo?: string
  isReferral: boolean
  isReferralInNewTab?: boolean
  website?: string
  referralLink?: string
}

export interface GoodOption {
  id: number
  category: string
  learnMoreLink: string
  good: Good
  passThroughTemplate?: OptionTemplate
}

export interface GoodUsage {
  goodOption: GoodOption
  message?: string
  usage: GoodVisibility
}

export interface OptionTemplate {
  id: number
  isActive: boolean
  seller: Seller
  buyerOptions: BuyerOption[]
  goodOptions: GoodOption[]
}

export interface Organization extends BaseModel {
  externalId: ExternalId
}

export interface Buyer extends Organization {
  sellers: Seller[]
  buyerPersons: BuyerPerson[]
  buyerDetails: BuyerDetail[]
}

export interface Seller extends Organization {
  sellerPageMessage?: string
  startButtonText1?: string
  startButtonText2?: string
  optionTemplates: OptionTemplate[]
  sellerPersons: SellerPerson[]
  districtGroups: DistrictGroup[]
}

export interface Person {
  id: number
  firstName?: string
  middleName?: string
  lastName?: string
  email: string
  emailOptIn?: boolean
  phone?: string
  phoneOptIn?: boolean
  defaultBuyerId: number
  buyers: BuyerPerson[]
}

export interface PersonValue {
  id?: number;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  email?: string;
  emailOptIn?: boolean
  phone?: string;
  phoneOptIn?: boolean;
  role?: string;
  permissionGroupId?: number
}

export interface PermissionGroup {
  id: number
  name: string
  permissions: Permission[]
  isSuperuser: boolean
  distributableGroups?: PermissionGroup[]
}

export interface BaseOrganizationPerson {
  id: number
  role: BaseModel
  person: Person
  permissionGroup: PermissionGroup
}

export interface BuyerPerson extends BaseOrganizationPerson {
  buyer: Buyer
}

export interface SellerPerson extends BaseOrganizationPerson {
  seller: Seller
}

export interface Transaction extends BaseModel {
  uuid: typeof uuid;
  process: Process;
  status: ProgressStatus;
  startDate: Date;
  completionDate: Date;
  customDataJson: CustomDataJson;
  externalId: ExternalId
  paymentPlan?: PaymentPlan
  activities: Activity[]
  hasContract: Boolean
  hasPayment: Boolean
  workflowItems: TransactionWorkflowItem[]
}

export interface TransactionWorkflowItem extends BaseModel {
  status: ProgressStatus
  workflowExternalIds: ExternalId[]
  startDate?: Date
  completionDate?: Date
}
export interface TransactionStep extends BaseModel {
  startDate?: Date;
  completionDate?: Date;
}

export interface Activity extends BaseModel {
  date: Date
  summary: string
  activityType: ActivityType
  details: ActivityDetails
  transactionStep?: TransactionStep;
}

export interface ActivityDetails {
  filepath?: string
  filepaths?: ActivityDetailFilepaths[]
  from?: string
  to?: string
  subject?: string
  content?: string 
}

interface ActivityDetailFilepaths {
  s3Key: string
  filepath: string
}

export interface PaymentPhase extends BaseModel {
  amountDue: number;
  amountPaid: number;
  priority: number;
  completionDate: Date;
  status: ProgressStatus
}

export interface PaymentPlan {
  id: number;
  status: PaymentStatus
  phases: PaymentPhase[]
}

export interface Process extends BaseModel {
  email: string;
  seller: Seller;
  bundle: Bundle;
}

export interface Bundle extends BaseModel {
  price: number;
  goods: Good[];
  owner: Seller;
  processes: Process[];
}

export interface CustomDataJson {
  [key: string]: any
}

export interface Boundary {
  id: number
  eeDistrictId: number
  eeDistrictName: string
  districtGroup: DistrictGroup
}

export interface DistrictGroup {
  id: number
  eeDistrictGroupId: number
  eeDistrictGroupName: string
  isActive: boolean
  displayName: string
  boundaries: Boundary[]
}

export enum BuyerDetailType {
  buyer = 'Buyer',
  buyerCore = 'BuyerCore',
  keyPerson = 'KeyPerson',
  otherPerson = 'OtherPerson'
}

export enum ProgressStatus {
  NotStarted = "NotStarted",
  InProgress = "InProgress",
  Complete = "Complete",
  Triage = "Triage",
  Skipped = "Skipped",
  Abandoned = "Abandoned",
  Canceled = "Canceled"
}

export enum ProgressStatusText {
  NotStarted = "Request in progress",
  InProgress = "Request in progress",
  Complete = "Access granted",
  Triage = "Request in progress",
  Skipped = "Request in progress",
  Abandoned = "Request closed",
  Canceled = "Request canceled"
}

export const COMPLETED_STATUSES = [
  ProgressStatus.Abandoned, ProgressStatus.Canceled, ProgressStatus.Complete
]

export const STARTED_STATUSES = [
  ProgressStatus.InProgress, ProgressStatus.Triage
]

enum PaymentStatus {
  NotConfigured,
  PaymentDue,
  OnTrack,
  PaymentOverdue,
  Overpaid,
  Complete,
  Error  
}

export enum ActivityType {
  Email = 'Email',
  ResendEmail = 'ResendEmail',
  ContractSigned = 'ContractSigned',
  ResendContract = 'ResendContract',
  Payment = 'Payment',
  S3FileUpload = 'S3FileUpload',
  Notification = 'Notification'
}

export enum Permission {
  RequestTool = 'RequestTool',
  MakePayment = 'MakePayment',
  SignContract = 'SignContract',
  ViewTransactionHistory = 'ViewTransactionHistory',
  ContactSeller = 'ContactSeller',
  CancelTransaction = 'CancelTransaction',
  EditUsers = 'EditUsers',
  CreateUsers = 'CreateUsers',
  EditBuyer = 'EditBuyer'
}

export enum SellerCodeOverride {
  BB = 'NC', // dev
  DB = 'AK', // dev second seller
  ES = 'CO', // testing
  DC = 'OH', // staging
  ED = 'WI', // prod
  EDB = 'WA', // prod2
  ADSP = 'AK',
  ARDEMS = 'AR',
  DEMSAZSP = 'AZ',
  DEMSFLSP = 'FL',
  DEMSOHSP = 'OH',
  DEMSNESP = 'NE',
  DEMSOK = 'OK',
  DEMSTNSP = 'TN',
  DEMSUTSP = 'UT',
  DEMSWASP = 'WA',
  IADEMS = 'IA',
  IDDEMS = 'ID',
  KDD = 'KY',
  SDPA = 'PA',
  WCS = 'WI',
}

export enum GoodVisibility {
  usable = 'Usable',
  unknown = 'Unknown',
  showMessage = 'ShowMessage',
  showMessageFixable = 'ShowMessageFixable'
}


export const DEFAULT_PERMISSION_GROUP_ID = 1