import _ from "lodash";
import { User } from "@auth0/auth0-react";
import { Box, Typography } from "@mui/material";

import { useGlobalStore } from "../../store";
import { Permission, Seller } from "../../interfaces";
import { SubsectionType, EditDetailSubsection } from "./EditDetailSubsection";
import { EditPersonSubsection } from "./EditPersonSubsection";
import { hasAnyPermission, hasPermission } from "../../utils/permissions";


interface EditCampaignProps {
  sellers?: Seller[]
  user?: User
  refresh?: Function
}

export const EditCampaign = (props: EditCampaignProps) => {
  const buyerPersons = useGlobalStore((state) => state.buyerPersons);
  const buyerWithDetails = useGlobalStore((state) => state.buyerWithDetails);

  if (!buyerWithDetails) {
    return null // Allows Home to fetch the buyer before showing the rest of the screen
  }

  const answeredOptions = buyerWithDetails.buyerDetails.map(buyerDetail => {
    return buyerDetail.option.id;
  })
  const allOptions = _.uniq(props.sellers?.map(seller => {
    return seller.optionTemplates.map(optionTemplate => {
      return optionTemplate.buyerOptions.map(buyerOption => {
        return buyerOption.uiElement === "EEDistrict" ? {
          ...buyerOption,
          districtGroups: seller.districtGroups
        } : buyerOption
      })
    })
  }).flat().flat());

  const districtGroups = _.uniq(props.sellers?.map(seller => {
    return seller.districtGroups
  }).flat());

  const prepopulatedBuyerDetails = buyerWithDetails?.buyerDetails.map(buyerDetail => {
    return buyerDetail.option.uiElement === "EEDistrict" ? {
      ...buyerDetail,
      option: { ...buyerDetail.option, districtGroups }
    } : buyerDetail
  })

  return (
    <Box sx={{pb: 4, pt:2, pr: 5, pl: 5}}>
      {!hasAnyPermission(
        buyerPersons,
        buyerWithDetails.id,
        [Permission.EditBuyer, Permission.EditUsers, Permission.CreateUsers]) &&
          <Typography variant="body1" align="left" sx={{mt: 4}}>
            See your committee admin to edit your campaign details.
          </Typography>
        }
      {hasPermission(buyerPersons, buyerWithDetails.id, Permission.EditBuyer) && <Box>
        <EditDetailSubsection
          key="Campaign"
          subsectionType={SubsectionType.existingDetail}
          sectionTitle="Campaign"
          buyerDetails={prepopulatedBuyerDetails}
          buyerId={buyerWithDetails.id}
          user={props.user}
          postSaveHook={props.refresh}
        />
        <EditDetailSubsection
          key="Tell us more"
          subsectionType={SubsectionType.newDetail}
          sectionTitle="Tell us more"
          buyerOptions={allOptions}
          answeredOptions={answeredOptions}
          buyerId={buyerWithDetails.id}
          user={props.user}
          postSaveHook={props.refresh}
        />
      </Box>}
      {hasPermission(buyerPersons, buyerWithDetails.id, Permission.EditUsers) && <Box>
        <Typography variant="h6" align="left">
          Edit users by role
        </Typography>
        {buyerWithDetails?.buyerPersons.map(buyerPerson => {
          return <EditPersonSubsection
            key={buyerPerson.id}
            isEditMode={true}
            buyerPerson={buyerPerson}
            user={props.user} />
        })}
      </Box>}
      {hasPermission(buyerPersons, buyerWithDetails.id, Permission.CreateUsers) &&
        <EditPersonSubsection key="editModeFalse" isEditMode={false} buyerId={buyerWithDetails.id} user={props.user} />}
    </Box>
  );
}