
import _ from 'lodash';
import {
  Box,
  Card, CardActionArea, CardContent,
  Grid, Typography
} from '@mui/material';
import { Campaign, DirectionsRun } from '@mui/icons-material';

import { useNavigate } from 'react-router-dom';
import { BuyerDetailsLocation } from '.';
import { OptionTemplate } from '../../interfaces';

const CardButton = (props: { cardText: string, optionTemplate: OptionTemplate, showStaffMessage?: boolean }) => {
  const navigate = useNavigate();

  const onClicked = () => {
    const navObj : BuyerDetailsLocation = {
      optionTemplate: props.optionTemplate,
      showStaffMessage: props.showStaffMessage
    }
  
    navigate('details', { state: navObj })
  }

  return (
    <Grid item sx={{ width: "100%" }}>
      <Card elevation={0}
        sx={{ border: 1, borderColor: '#EAEAEA', mb: 1, mr: 2 }}
      >
        <CardActionArea onClick={onClicked}
          sx={{ pl: 3, pr: 3, pt: 2, pb: 2, }}>
          <Grid container component={CardContent}>
            <Grid item xs>
              <Typography variant="body1" fontWeight={500}>
                { props.cardText }
              </Typography>
            </Grid>
            <Grid item xs={1} display={'flex'}>
              {props.showStaffMessage ? 
                <Campaign color="secondary" /> : <DirectionsRun color="secondary"/>
              }
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
    </Grid>
  )
}

const GetStarted = (props: {optionTemplate: OptionTemplate}) => {
  const hasCustomText = !_.isEmpty(props.optionTemplate.seller?.startButtonText1)
  const hasTwoButtons = !hasCustomText || !_.isEmpty(props.optionTemplate.seller?.startButtonText2)
  return (
    <Box sx={{
      justifyContent: "space-between", 
      paddingTop: 0, paddingLeft: 3, paddingRight: 3, paddingBottom: 4 }}>
      <Typography variant="overline" pb={1}>
        Let's get started.
      </Typography>    
      { hasCustomText ||
        <Typography variant="h5" align={'left'} pb={2}>
          Are you a candidate or considering running for office?
        </Typography>
      }  
      <Grid container spacing={1}>
        <Grid item xs={12} md={hasTwoButtons ? 6 : 12}>
          <CardButton
            cardText={props.optionTemplate.seller?.startButtonText1 || "I'm running for office"}
            optionTemplate={props.optionTemplate}
          />
        </Grid>
        { hasTwoButtons &&  
          <Grid item xs={12} md={6}>
            <CardButton
              cardText={props.optionTemplate.seller?.startButtonText2 || "I represent a club, group, or organization"}
              optionTemplate={props.optionTemplate}
              showStaffMessage={_.isEmpty(props.optionTemplate.seller?.startButtonText2)}
            />
          </Grid>
          }
      </Grid>
    </Box>
  )
}

export default GetStarted;