import { Container } from '@mui/material';
import CampaignHeader from './CampaignHeader';
import CampaignsTable from './CampaignTable';
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getAccount, getBuyerTransactions } from '../../queries';
import { useQuery } from '@apollo/client';
import CircularProgressBackdropSmall from '../common/CircularProgressBackdropSmall';


const CampaignPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const { user } = useAuth0();

  const { loading: isAccountLoading, data: accountData } = useQuery(
    getAccount,
    {
      variables: { email: user?.email },
      skip: !user?.email,
    }
  );

  const sellerId = accountData?.account?.person?.sellers[0]?.sellerId

  const { loading: isBuyerTransactionsLoading, data: buyerTransactionsData } = useQuery(
    getBuyerTransactions,
    {
      variables: { id: sellerId },
      skip: !sellerId,
    }
  );

  const isLoading = isAccountLoading || isBuyerTransactionsLoading;

  const hasError = !accountData || !buyerTransactionsData;


  if (isLoading) {
    return (
      <CircularProgressBackdropSmall/>
    );
  }

  if (hasError) {
    return <div>Error loading data</div>; 
  }


  return (
    <Container maxWidth="xl" style={{ paddingTop: '40px' }} >

      <CampaignHeader searchQuery={searchQuery} setSearchQuery={setSearchQuery} />

      <CampaignsTable searchQuery={searchQuery} buyerTransactionsData={buyerTransactionsData} />

    </Container>
  );
}

export default CampaignPage;

