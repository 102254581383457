import { Alert, Box, Button, Typography } from '@mui/material';
import { useLocation, useParams, useNavigate } from "react-router-dom";
import ReactGA from 'react-ga4';

import { useLambdas } from "../utils/lambdas";
import React, { useEffect } from 'react';
import { CircularProgressBackdrop } from './common';

const VALID_REDIRECT_TIMEOUT: number = 1000;
const MESSAGE_REDIRECT_TIME: number = 20000;
const INVALID_REDIRECT_TIMEOUT: number = 3000;

function Payment() {

  const { transactionId } = useParams();
  const navigate = useNavigate();

  const search = useLocation().search;
  const urlSearchParams = new URLSearchParams(search)

  const paymentType = urlSearchParams.get('paymentType');

  console.log(paymentType)

  let checkoutSessionParams: { uuid?: string, operationType?: string, isUpdatePayment: boolean } = {
    uuid: transactionId,
    isUpdatePayment: Boolean(urlSearchParams.get('isUpdatePayment'))
  } 

  if (paymentType) {
    checkoutSessionParams.operationType = paymentType;
  }

  ReactGA.event({
    category: "payment",
    action: "redirectToPayment",
    label: `redirect to payment page of type ${paymentType || 'N/A'}`
  });
 
  const [{ data, loading, error }] = useLambdas(
    "generateCheckoutSession",
    undefined,
    checkoutSessionParams,
    false
  )

  useEffect(() => {
    if (! loading && ! error) {
      console.log(data)
      if (data && data.message) {
        setTimeout(() => {
          navigate(`/`)
        }, MESSAGE_REDIRECT_TIME);
      } else if (null === data || null === data.redirectUrl ) {
        setTimeout(() => {
          navigate(`/`)
        }, INVALID_REDIRECT_TIMEOUT);
      } else {
        console.log(`navigating to ${data.redirectUrl}`)
        setTimeout(() => {
          window.location.href = data.redirectUrl;
        }, VALID_REDIRECT_TIMEOUT);
      }
    }
  }, [ navigate, data, loading, error ]);


  if (error) {
    console.error(error);
    
    return(
      <Alert severity="error">
        There was an error loading the page.
        Please refresh your page and try again, or contact the seller.
      </Alert>
    )
  }

  return (
    <Box sx={{ p: 4 }}>
      <CircularProgressBackdrop open={loading} />
      { loading && 
        <Typography variant="h6">
          We're fetching your payment link!
        </Typography>
      }
      { ! loading && data && data.message &&
        <Box>
          <Typography variant="h6">
            {data.message}
            You will be redirected soon
          </Typography>
          <Button 
            variant="text"
            href='/'>
              Click here if you want to leave this page sooner.
          </Button>
        </Box>      
      }
      { ! loading && data && !data.message && data.redirectUrl &&
        <Box>
          <Typography variant="h6">
            You are being redirected to your payment page
          </Typography>
          <Button 
            variant="text"
            href={data.redirectUrl}>
              Click here if you are not redirected within 5 seconds.
          </Button>
        </Box>      
      }
      { ! loading && data && !data.message && null === data.redirectUrl && 
        <Box>
          <Typography variant="h6">
            Thank you - you have already paid your invoice in full!
          </Typography>
          <Button 
            variant="text"
            onClick={() => navigate(`/`)}>
              Click here if you are not redirected within 5 seconds.
          </Button>
        </Box>  
      }
    </Box>
  );
}

export default Payment;
