import { Link, Typography, TypographyProps } from '@mui/material';

interface LinkifyTextProps extends TypographyProps {
  text: string;
}

export default function LinkifyText({ text, variant, ...typographyProps }: LinkifyTextProps) {
  if (!text) {
    return <></>;
  }
  // find urls in text
  const urlRegex = /\b((https?:\/\/)?(www\.)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(\/[^\s]*)?)\b/g;

  const convertTextToLinks = (text: string) => {
    const elements = [];
    let currentIndex = 0;
    let urlMatch;

    while ((urlMatch = urlRegex.exec(text)) !== null) {
      if (urlMatch.index > currentIndex) {
        elements.push(text.substring(currentIndex, urlMatch.index));
      }
      elements.push(
        <Link key={urlMatch[0]} href={urlMatch[0].startsWith('http') ? urlMatch[0] : `http://${urlMatch[0]}`} target="_blank" rel="noopener noreferrer">
          {urlMatch[0]}
        </Link>
      );
      currentIndex = urlMatch.index + urlMatch[0].length;
    }
    if (currentIndex < text.length) {
      elements.push(text.substring(currentIndex));
    }
    return elements;
  };

  return (
    <Typography variant={variant} component="span">
      {convertTextToLinks(text)}
    </Typography>
  );
}
