import { TableCell, TableCellProps } from '@mui/material';
import { styled } from '@mui/system';

interface StyledTableCellProps extends TableCellProps {
  textAlign?: 'left' | 'center' | 'right' | 'justify' | 'inherit';
}
// using important to allow for TableCell Borders, the MuiTableRow theme was preventing it
const StyledTableCell = styled(TableCell)<StyledTableCellProps>(({ theme }) => ({
  borderBottom: '1px solid #CECECE !important',
  color: theme.palette.text.primary,
  textAlign: 'right', 
}));

export default StyledTableCell;

