import {
  Checkbox, Collapse, FormControl, FormControlLabel,
  FormGroup, FormHelperText, FormLabel, Grid,
  Switch,
  TextField, Typography
} from '@mui/material';

import { BuyerOptionProps, isPersonBuyerOptionValues } from '.';

import _ from 'lodash';
import { ChangeEventHandler, Fragment, useEffect, useState } from 'react';
import { DEFAULT_PERMISSION_GROUP_ID, PersonBuyerOptionValue } from '../../../interfaces';
import { MaskTextField } from '../../common';
import { validateEmail } from '../../../utils/email';
import { validatePhone } from '../../../utils/phone';
import { setPersonDataValue } from '../../../utils/person';

export const buildGrid = (
  label: string, 
  key: string,
  value: string, 
  isRequired: boolean,
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  helperText?: string,
  error?: boolean,
  isOptIn?: boolean,
  isChecked?: boolean,
  disableRoleEdit: boolean = false
): JSX.Element => {
  return <Fragment key={key}>
    <Grid item xs={12} sm={isOptIn ? 8 : 12} md={isOptIn ? 4 : 6}>
      <FormControl fullWidth >
        {key === 'phone' ? <MaskTextField
          required={isRequired}
          value={value}
          onChange={onChange}
          name={key}
          label={label}
          mask="(000) 000-0000"
          error={error}
        /> : <TextField 
          required={isRequired}
          value={value}
          onChange={onChange}
          error={error}
          name={key}
          label={label}
          disabled={key === 'role' ? disableRoleEdit : false}
        />}
        {
          helperText && 
          <FormHelperText>
            {helperText}
          </FormHelperText>
        }
      </FormControl>
    </Grid>
    {isOptIn && <Grid item xs={12} sm={4} md={2} sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
      <FormControl fullWidth sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
        <FormControlLabel 
          control={
            <Switch name={`${key}OptIn`}
              disabled={!value}
              checked={isChecked}
              onChange={onChange} />
          } 
          label={
            <Typography variant="caption">
              Opt in to {key === 'phone' ? 'texts' : 'emails'}
            </Typography>
          } />
      </FormControl>
    </Grid>}
  </Fragment>
}

export default function Person(props: BuyerOptionProps) {
  const personOptionValues = isPersonBuyerOptionValues(props.value) ? props.value : undefined;
  const personFields = personOptionValues ? _.sortBy(personOptionValues.fields, (e) => {
    return e.order;
  }) : undefined;
  const onErrorChange = props.onErrorChange;

  const [ emailError, setEmailError ] = useState(false);
  const [ phoneError, setPhoneError ] = useState(false);
  const [ isSamePerson, setIsSamePerson ] = useState(false);

  const [ personValues, setPersonValue ] = useState({
    firstName: '',
    lastName: '',
    middleName: '',
    email: '',
    emailOptIn: true,
    phone: '',
    phoneOptIn: true,
    role: props.label,
    permissionGroupId: DEFAULT_PERMISSION_GROUP_ID
  });

  useEffect(() => {
    const isEmailValid = personValues.email === '' || validateEmail(personValues.email);
    const isPhoneValid = personValues.phone === '' || validatePhone(personValues.phone);
    setEmailError(!isEmailValid);
    setPhoneError(!isPhoneValid);
  }, [personValues.email, personValues.phone]);

  useEffect(() => {
    onErrorChange && onErrorChange(props.id, emailError || phoneError);
  }, [onErrorChange, props.id, emailError, phoneError]);

  useEffect(() => {
    if (props.onDetailChange) {
      props.onDetailChange(props.label, personValues, props.id);
    }
  // jrose 14-Jun-22: if we add props to below, the component gets stuck in an endless update loop
  // disabling eslint check but maybe in the future we can figure out why
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personValues])

  useEffect(() => {
    if (isSamePerson && props.firstPersonValue) {
      setPersonValue({
        ...personValues,
        firstName: props.firstPersonValue.firstName || "",
        lastName: props.firstPersonValue.lastName || "",
        middleName: props.firstPersonValue.middleName || "",
        email: props.firstPersonValue.email || "",
        emailOptIn: props.firstPersonValue.emailOptIn || true,
        phone: props.firstPersonValue.phone || "",
        phoneOptIn: props.firstPersonValue.phoneOptIn || true,
        permissionGroupId: props.firstPersonValue.permissionGroupId || DEFAULT_PERMISSION_GROUP_ID
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSamePerson, props.firstPersonValue])

  const handleChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsSamePerson(e.target.checked);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPersonValue({
      ...personValues,
      ...setPersonDataValue(e)
    });
  }

  const samePersonCheckbox = !props.firstPersonKey || props.firstPersonKey === props.optionKey ? null : (
    <Grid item xs={12} sm={12}>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox onChange={handleChecked} />}
          label={
            <Typography variant="body2">
              Check this box if the {props.label.toLowerCase()} is also the {props.firstPersonLabel?.toLowerCase()}
            </Typography>
          }
        />
      </FormGroup>
    </Grid>
  )

  const isEmailError: boolean = personValues.email !== '' && emailError;
  const isPhoneError: boolean = personValues.phone !== '' && phoneError;

  return (
    <Grid container width="100%">
      <Grid item xs={12}>
        <FormLabel component="legend" sx={{ pb: 1 }}>
          <Typography color="textPrimary">{props.label}</Typography>
        </FormLabel>
      </Grid>
      {samePersonCheckbox}
      <Collapse in={!isSamePerson}>
        <Grid container spacing={2} component={FormGroup}>
          { personFields?.map((personField: PersonBuyerOptionValue) => {
            const { label, key, is_required } = personField;
            return (
              buildGrid(
                label,
                _.camelCase(key),
                personValues[key],
                isSamePerson ? false : is_required,
                handleChange,
                key === 'email' ? (isEmailError ? "Please enter a valid email" : '') :
                key === 'phone' ? (isPhoneError ? "Please enter a valid 10 digit phone number" : '') : undefined,
                key === 'email' ? isEmailError :
                key === 'phone' ? isPhoneError : undefined,
                ['email', 'phone'].includes(key),
                (key === 'email' || key === 'phone') && personValues[key] !== "" && personValues[`${key}OptIn`],
                props.label.toLowerCase() === 'point of contact'
              )
            )
          })}
        </Grid>
      </Collapse>
    </Grid>
  );
}
