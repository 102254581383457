import { CircularProgress, Grid, Typography } from "@mui/material";

const CircularProgressBackdropSmall = () => {
  return (
    <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ height: '80vh' }}>
      <CircularProgress />
      <Typography variant="body1" sx={{ marginTop: '10px' }}>
        Loading data, please wait...
      </Typography>
    </Grid>
  );
}

export default CircularProgressBackdropSmall;