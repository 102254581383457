import { Grid } from '@mui/material';

import { BuyerOption, PersonValue } from '../../interfaces';
import { BuyerOptionRegistry } from './buyerOptions';

const registry = new BuyerOptionRegistry();

export const BaseBuyerOption = (props: {
  option: BuyerOption, 
  onDetailChange: Function,
  onErrorChange?: Function,
  isEditMode?: boolean,
  currentValue?: any,
  isDisabled?: boolean,
  buyerDetailId?: number,
  externalId?: string,
  firstPersonKey?: string,
  firstPersonLabel?: string,
  firstPersonValue?: PersonValue}) => 
{
  const option = props.option;
  const Component = registry.getComponent(option.uiElement);
  return (
    <Grid item xs={12} key={option.id} sx={{ mb: 2 }}>
      <Component {...option} optionKey={option.key} onDetailChange={props.onDetailChange}
        isEditMode={props.isEditMode} currentValue={props.currentValue}
        isDisabled={props.isDisabled} buyerDetailId={props.buyerDetailId}
        externalId={props.externalId}
        firstPersonKey={props.firstPersonKey} firstPersonLabel={props.firstPersonLabel}
        firstPersonValue={props.firstPersonValue}
        onErrorChange={props.onErrorChange}
      />
    </Grid>
  );
}