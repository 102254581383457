import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

interface WarningDialogProps {
  open: boolean;
  onClose: () => void;
}

const WarningDialog = ({ open, onClose }: WarningDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Insufficient Permissions</DialogTitle>
      <DialogContent>
        <Typography>
          You don't have enough permissions to view this page. You will be redirected to the admin login.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WarningDialog;
