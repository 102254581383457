import { useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { getToolTransactions } from "../../queries";
import { Box, Grid, IconButton, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import DetailsToolsSection from "./DetailsToolsSection";
import DetailsAboutSection from "./DetailsAboutSection";
import CircularProgressBackdropSmall from "../common/CircularProgressBackdropSmall";
import { Transaction } from "../../interfaces";
import { GoodLastTransaction, GroupedTransactions, filterGoodsByStatus } from "./utils"
import { TabPanel } from '../common/TabPanel';
import { useState } from "react";
import { theme } from "../../utils/theme";
import { useLambdas } from "../../utils/lambdas";
import { useAuth0 } from "@auth0/auth0-react";

interface Good {
  id: number
  name: string
  message?: string
}

interface GoodObject {
  good: Good
  usage: string
  message: string
}

function getVisibleGoods(goods: GoodObject[]): { id: number, name: string }[] {
  const goodsWithoutMessage = goods.filter(item => {
    if (!item.message) {
      return true;
    }
    return false;
  })
    .map(item => ({
      id: item.good.id,
      name: item.good.name
    }));

  return goodsWithoutMessage;
}

function combineAndSortGoodsAndTransactions(
  goods: Good[],
  mostRecentGoodTransactions: { [key: string]: Transaction }
): GoodLastTransaction[] {
  const combineGoodsAndTransactions = goods.map((good) => {
    const matchingTransaction = Object.values(mostRecentGoodTransactions).find(
      (transaction: Transaction) =>
        transaction.process.bundle &&
        transaction.process.bundle.goods[0].id === good.id
    );

    return {
      ...good,
      transaction: matchingTransaction || null,
    };
  });

  const missingGoods = Object.values(mostRecentGoodTransactions).filter(
    (transaction: Transaction) =>
      !goods.some(
        (good: Good) =>
          transaction.process.bundle &&
          transaction.process.bundle.goods[0].id === good.id
      )
  ).map((transaction: Transaction) => ({
    id: transaction.process.bundle.goods[0].id,
    name: transaction.process.bundle.goods[0].name,
    transaction,
  }));

  return [...combineGoodsAndTransactions, ...missingGoods].sort((a, b) => {
    if (!a.transaction && b.transaction) return 1;
    if (a.transaction && !b.transaction) return -1;
    return 0;
  });
}

function groupTransactionsByGoodName(transactions: Transaction[]): GroupedTransactions {
  return transactions.reduce((acc: GroupedTransactions, transaction: Transaction) => {
    const goodName = transaction.process.bundle.goods[0].name;

    if (!acc[goodName]) {
      acc[goodName] = [];
    }

    acc[goodName].push(transaction);
    return acc;
  }, {});
};


function getMostRecentGoodTransaction(groupedTransactions: GroupedTransactions): { [goodName: string]: Transaction } {
  const highestIdTransactions: { [goodName: string]: Transaction } = {};

  Object.keys(groupedTransactions).forEach((goodName: string) => {
    const transactions = groupedTransactions[goodName];

    const highestTransaction = transactions.reduce((prev: Transaction, current: Transaction) =>
      prev.id > current.id ? prev : current
    );

    highestIdTransactions[goodName] = highestTransaction;
  });

  return highestIdTransactions;
};

const CampaignDetailsPage = () => {
  const { id } = useParams();
  const { user } = useAuth0();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const isMediumBreakpoint = useMediaQuery(theme.breakpoints.down('md'));

  const handleChange = (event: React.SyntheticEvent, tabValue: number) => {
    setTabValue(tabValue);
  };

  const { loading: isQueryLoading, data: buyerData } = useQuery(
    getToolTransactions,
    {
      variables: { id: Number(id) },
    },
  );

  const buyer = buyerData?.buyer || {};
  const { buyerDetails, buyerPersons, transactions } = buyer;

  const templateId = _.get(buyerDetails, '[0].option.templateId', '0');

  const [
    { data: potentialGoodsForBuyer = { goods: [] },
      loading: arePotentialGoodsLoading
    }
  ] = useLambdas(
    "getBuyerPotentialGoods",
    user,
    {
      templateId: templateId,
      buyerId: id
    },
    false
  );

  if (isQueryLoading || arePotentialGoodsLoading) {
    return (
      <CircularProgressBackdropSmall />
    );
  }

  // maybe add useMemo in the future, if there are hundreds of goods and hundreds of transactions. 
  const visibleGoods = getVisibleGoods(potentialGoodsForBuyer.goods);
  const groupedTransactions = groupTransactionsByGoodName(transactions);
  const mostRecentGoodTransaction = getMostRecentGoodTransaction(groupedTransactions);
  const goodsToDisplay = combineAndSortGoodsAndTransactions(visibleGoods, mostRecentGoodTransaction);
  const goodsToDisplayWithDescriptionStatusText = filterGoodsByStatus(goodsToDisplay)

  return (
    <Grid container direction='column' spacing={2} sx={{ padding: '20px' }}>
      <Grid item>
        <Box onClick={() => navigate('/admin/campaigns')} sx={{ cursor: 'pointer' }}>
          <Grid container alignItems='center' spacing={0}>
            <Grid item>
              <IconButton sx={{ color: theme.palette.common.navy }} disableRipple>
                <ArrowBack />
              </IconButton>
            </Grid>
            <Grid item sx={{ marginTop: '2px' }}>
              <Typography variant='overline' color='textPrimary'>
                ALL CAMPAIGNS
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Typography variant='h3' color='textPrimary' sx={{ marginTop: '10px' }}>
          {buyerData.buyer.name}
        </Typography>
      </Grid>

      {isMediumBreakpoint ? (
        <>
          <Grid item>
            <Tabs value={tabValue} onChange={handleChange} variant="fullWidth">
              <Tab label="Tools" />
              <Tab label="About" />
            </Tabs>
          </Grid>

          <TabPanel value={tabValue} index={0}>
            <Grid item xs={12}>
              {!isMediumBreakpoint && (
                <Typography variant='h4' sx={{ marginBottom: '20px' }}>
                  Tools
                </Typography>
              )}
              {goodsToDisplayWithDescriptionStatusText && goodsToDisplayWithDescriptionStatusText.length > 0 &&
                goodsToDisplayWithDescriptionStatusText.map((goodToDisplay, index: number) => {
                  const { name, transaction, goodDescriptionStatusText } = goodToDisplay;
                  return (
                    <DetailsToolsSection
                      key={index}
                      name={name}
                      transaction={transaction}
                      goodDisplayDescription={goodDescriptionStatusText}
                    />
                  );
                })
              }
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {/* About Section */}
            <Grid item xs={12}>
              {!isMediumBreakpoint && (
                <Typography variant='h4' sx={{ marginBottom: '20px' }}>
                  About
                </Typography>
              )}
              <DetailsAboutSection buyerDetails={buyerDetails} buyerPersons={buyerPersons} />
            </Grid>
          </TabPanel>
        </>
      ) : (
        <Grid item container spacing={3} sx={{ marginTop: '10px' }}>
          {/* Tools Section */}
          <Grid item xs={12} md={7}>
            <Typography variant='h4' sx={{ marginBottom: '20px' }}>Tools</Typography>
            {goodsToDisplayWithDescriptionStatusText && goodsToDisplayWithDescriptionStatusText.length > 0 &&
              goodsToDisplayWithDescriptionStatusText.map((goodToDisplay, index: number) => {
                const { name, transaction, goodDescriptionStatusText } = goodToDisplay;
                return (
                  <DetailsToolsSection
                    key={index}
                    name={name}
                    transaction={transaction}
                    goodDisplayDescription={goodDescriptionStatusText}
                  />
                );
              })
            }
          </Grid>
          {/* About Section */}
          <Grid item xs={12} md={5}>
            <Typography variant='h4' sx={{ marginBottom: '20px' }}>About</Typography>
            <DetailsAboutSection buyerDetails={buyerDetails} buyerPersons={buyerPersons} />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default CampaignDetailsPage