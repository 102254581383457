import { useQuery } from "@apollo/client";

import { Alert, Box, Typography } from '@mui/material';
import { CircularProgressBackdrop } from './common';
import ReactGA from 'react-ga4';

import { OptionTemplate } from '../interfaces';
import { getAllActiveTemplates } from '../queries';
import Welcome from './newCampaign/Welcome';
import SellerInfoCard from './seller/SellerInfoCard';

import { useTheme } from '@mui/material/styles';


function Landing() {
  const theme = useTheme();
  
  const { loading, error, data: templatesWithStates } = useQuery(getAllActiveTemplates);

  ReactGA.event({
    category: "landing page",
    action: "viewLandingPage",
    label: "view landing page listing sellers"
  });

  if (error) {
    console.error(error);
    
    return(
      <Alert severity="error">
        There was an error loading the page.
        Please refresh your page and try again, or contact the seller.
      </Alert>
    )
  }
  let optionTemplates: OptionTemplate[] = []
  if (templatesWithStates) {
    optionTemplates = [...templatesWithStates.optionTemplates]
    optionTemplates.sort((a, b) => {
      if (a.seller.name > b.seller.name) {
        return -1
      }
      if (a.seller.name < b.seller.name) {
        return -1
      }
      return 0
    })
  }

  return (
    <Box sx={{ pt: 4 }}>
      <CircularProgressBackdrop open={loading} />
      <Welcome /> 
      <Box sx={{ backgroundColor: theme.palette.common.yellowBackground, pb: 2, pt:2, pr: 6, pl: 6}}>
        <Typography variant={'h5'} sx={{ paddingBottom: 2 }}>
          Available Sellers
        </Typography>
        { optionTemplates.map((template: OptionTemplate) => (
          <SellerInfoCard key={template.id} template={template}/>
        ))}
      </Box>
    </Box>
  );
}

export default Landing;
