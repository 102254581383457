import { List, ListItem, Typography } from "@mui/material"
import { theme } from "../../utils/theme"

interface GoodDisplayMessage {
  displayHistoryMessage?: string
  date?: Date | string
}

interface TransactionHistoryProps {
  goodDisplayMessages: GoodDisplayMessage[]
}

const goodDisplayMessageMapping: { [key: string]: string } = {
  'Open': 'Good Requested',
  'Handle Contracts': 'Contracts in Progress',
  'Contracts in Progress': 'Contract Approved'
};

const ToolSectionTransactionHistory = (props: TransactionHistoryProps) => {
  const { goodDisplayMessages } = props

  return (
    <List sx={{ marginTop: '20px' }}>
      {goodDisplayMessages.map((goodDisplay, index) => (
        <ListItem key={index} divider sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
          <Typography variant="body2" sx={{ color: theme.palette.common.lightGrey }}>
            {goodDisplayMessageMapping[goodDisplay.displayHistoryMessage || ''] || goodDisplay.displayHistoryMessage}
          </Typography>
          <Typography variant="caption" sx={{ marginLeft: 'auto', color: theme.palette.common.lightGrey }}>
            {goodDisplay.date
              ? new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              }).format(new Date(goodDisplay.date)) +
              ' | ' +
              new Intl.DateTimeFormat('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
                timeZoneName: 'short',
              }).format(new Date(goodDisplay.date))
              : 'No date found'}
          </Typography>
        </ListItem>
      ))}
    </List>
  );
};

export default ToolSectionTransactionHistory