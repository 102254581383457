import { useState } from 'react';
import { User } from "@auth0/auth0-react";

import { Alert, Box, Button, Dialog, DialogContent, DialogTitle, Grid, 
          MenuItem, TextField, Typography } from '@mui/material';

import { Good, COMPLETED_STATUSES, Transaction } from '../../interfaces';
import { useLambdas } from '../../utils/lambdas';

interface TransactionProps {
  good: Good
  transaction: Transaction | undefined
  refetchPage?: Function
  user: User | undefined
}

export function TransactionCancelation(props: TransactionProps) {

  const [open, setOpen] = useState(false);
  const [cancelationReason, setCancelationReason] = useState('');
  const [isFailedSubmission, setIsFailedSubmission] = useState(false);

  const [{ response: cancelResponse }, executePost] = useLambdas(
    'cancelTransaction', 
    props.user,
    {
      detail: {
        transactionId: props.transaction?.id,
      },
      cancelationReason: cancelationReason
    }
  )

  if (cancelResponse && cancelResponse.status === 200) {
    if (props.refetchPage) {
      props.refetchPage()
    }
  }
  
  async function cancelTransaction() {
    try {
      return await executePost();
    } catch (error) {
      return setIsFailedSubmission(true);
    }
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const transaction = props.transaction
  const good = props.good
  for (const status of COMPLETED_STATUSES) {
    if (status === transaction?.status) {
      return <div />
    }
  }

  if (props.good.isReferral) {
    return <div />
  }

  return (
    <Box>
      <MenuItem onClick={handleClickOpen}>
        <Typography variant={'body2'}>Cancel request</Typography>
      </MenuItem>
      <Dialog
        open={open}
        maxWidth={'xl'}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Grid container>
            <Grid item xs={12}>
              <Typography variant={'h6'}>Cancel {good.name} request</Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <form
            onSubmit={ 
              event => {
                event.preventDefault();
                cancelTransaction();
              }
            }
          >
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <TextField 
                id="CancelTransactionReason"
                label="Reason"
                required
                multiline
                fullWidth
                minRows="3"
                onChange={event => {
                  setCancelationReason(event.target.value)
                }}
                />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <Button variant="contained" disableElevation type="submit">
                    Cancel Request
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12}>
                { isFailedSubmission ? 
                  <Alert severity="error">Unable to cancel request for { props.good.name }. 
                  Please try again or contact {props.transaction?.process.seller.name}</Alert>
                  : "" }
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  )
}
