import React, { FormEventHandler, Fragment, useEffect, useState } from "react";
import { Box, Button, FormControlLabel, Grid, IconButton, MenuItem, Select, Skeleton,
  SelectChangeEvent, Switch, TextField, Typography } from "@mui/material";
import { EditOutlined } from "@mui/icons-material";
import ReactGA from 'react-ga4';

import { useGlobalStore } from "../../store";
import { BuyerPerson, PersonValue, PermissionGroup } from "../../interfaces";
import { CircularProgressBackdrop, MaskTextField } from '../common';

const EXPECTED_PHONE_LEN = 14;

export enum EditPersonType {
  selfEdit,
  buyerEdit,
  buyerAdd
}

interface EditPersonProps {
  sendToHandler: Function
  handleInputChange: FormEventHandler<any>
  sectionTitle: string
  editMode: EditPersonType
  isLoaded: boolean
  personValues?: PersonValue
  hasAccount?: boolean
  handleAccountChecked?: FormEventHandler<any>
  handleSelectChange?: (e: SelectChangeEvent<number>) => void
}

function getDistributablePermissionGroups(buyerPersons: BuyerPerson[]) {
  let distributablePermissionGroups: PermissionGroup[] = []
  for (let buyerPerson of buyerPersons) {
    if (buyerPerson.permissionGroup?.distributableGroups?.length) {
      distributablePermissionGroups.push(...buyerPerson.permissionGroup.distributableGroups)
    }
  }
  distributablePermissionGroups = [...new Set(distributablePermissionGroups)].sort(
    function (permissionGroupA, permissionGroupB) {
      if (permissionGroupA.name > permissionGroupB.name) {
        return 1
      } 
      if (permissionGroupA.name < permissionGroupB.name) {
        return -1
      } 
      return (permissionGroupA.id > permissionGroupB.id ? 1 : -1)
    }
  )
  return distributablePermissionGroups
}

const validatePhone = (phone?: string) => {
  if (phone) {
    return EXPECTED_PHONE_LEN === phone.length;
  } 
  
  return true;
};

const isFieldInvalid = (field?: string) => {
  return !(undefined !== field && field.length > 0);
}

export const EditPerson = (props: EditPersonProps) => {
  const [ isLockedMode, setIsLockedMode ] = useState(true);
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ phoneError, setPhoneError ] = useState(true);
  const [ firstNameError, setFirstNameError ] = useState(false);
  const [ lastNameError, setLastNameError ] = useState(false);

  const currentBuyerPersons = useGlobalStore((state) => state.currentBuyerPersons);
  const distributablePermissionGroups = getDistributablePermissionGroups(currentBuyerPersons)

  useEffect(() => {
    setPhoneError(validatePhone(props.personValues?.phone));
  }, [props.personValues?.phone]);

  useEffect(() => {
    if (props.editMode !== EditPersonType.buyerAdd) {
      setFirstNameError(isFieldInvalid(props.personValues?.firstName));
    }
  }, [props.personValues?.firstName, props.editMode]);

  useEffect(() => {
    if (props.editMode !== EditPersonType.buyerAdd) {
      setLastNameError(isFieldInvalid(props.personValues?.lastName));
    }
  }, [props.personValues?.lastName, props.editMode]);

  const onSubmitClicked = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setIsSubmitting(true);

    ReactGA.event({
      category: "edit person",
      action: "updatePersonInfo",
      label: "edit person info, submit clicked"
    });
  
    try {
      await props.sendToHandler();
    } catch (err) {
      console.log(err)
    }

    setIsSubmitting(false);
    setIsLockedMode(true);
  }

  const permissionGroupId = props.personValues?.permissionGroupId || (distributablePermissionGroups.length ? distributablePermissionGroups[0].id : -1)
  // Don't allow editing of an account of a stac staff member or someone else that the current user can't edit
  if (props.editMode === EditPersonType.buyerEdit &&
     !distributablePermissionGroups.find(permissionGroup => {return permissionGroup.id === permissionGroupId})) {
    return (<div/>)
  }

  return (
    <Box component="form" onSubmit={onSubmitClicked} sx={{ mb: 2 }}>
      <Grid container spacing={2} alignItems="center">
        {props.editMode !== EditPersonType.buyerEdit && <Grid item xs={10} sx={{ mb: 2 }}>
          <Typography variant={props.editMode === EditPersonType.selfEdit ? "h5": "h6"} align="left">
            {props.sectionTitle}
          </Typography>
        </Grid>}
        <Grid item xs={props.editMode !== EditPersonType.buyerEdit ? 2 : 12} sx={{ mb: 2 }}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={() => setIsLockedMode(false)}>
              <EditOutlined color="primary" />
            </IconButton>
          </Box>
        </Grid>
        <Grid item sm={12} md={4} lg={4} xl={4}>
          { props.isLoaded ? 
            <TextField
              required
              disabled={isLockedMode}
              fullWidth
              id="first-name"
              label="First name"
              name="firstName"
              error={firstNameError}
              helperText={ firstNameError ? "Please enter at least one letter for a first name" : "" }
              onChange={props.handleInputChange}
              value={props.personValues?.firstName || ''}
            /> 
            : <Skeleton variant="rectangular" width={"100%"}> <TextField/> </Skeleton> }
        </Grid>
        <Grid item sm={12} md={4} lg={4} xl={4}>
          { props.isLoaded ? 
            <TextField
              disabled={isLockedMode}
              fullWidth
              id="middle-name"
              label="Middle name"
              name="middleName"
              onChange={props.handleInputChange}
              value={props.personValues?.middleName || ''}
            />
            : <Skeleton variant="rectangular" width={"100%"}> <TextField/> </Skeleton> }
        </Grid>
        <Grid item sm={12} md={4} lg={4} xl={4}>
          { props.isLoaded ? 
          <TextField
            required
            disabled={isLockedMode}
            fullWidth
            id="last-name"
            label="Last name"
            name="lastName"
            error={lastNameError}
            helperText={ lastNameError ? "Please enter at least one letter for a last name" : "" }
            onChange={props.handleInputChange}
            value={props.personValues?.lastName || ''}
          />
          : <Skeleton variant="rectangular" width={"100%"}> <TextField/> </Skeleton> }
        </Grid>
        <Grid item sm={12} md={6} lg={6} xl={6}>
          { props.isLoaded ? 
          <TextField
            required
            disabled={isLockedMode || props.editMode !== EditPersonType.buyerAdd}
            fullWidth
            id="email"
            label="Email"
            name="email"
            onChange={props.handleInputChange}
            value={props.personValues?.email || ''}
            InputProps={{
              readOnly: props.editMode !== EditPersonType.buyerAdd
            }}
          />
          : <Skeleton variant="rectangular" width={"100%"}> <TextField/> </Skeleton> }
        </Grid>
        <Grid item sm={12} md={6} lg={6} xl={6}>
          { props.isLoaded ? 
          <MaskTextField
            disabled={isLockedMode}
            fullWidth
            id="phone-number"
            label="Phone number"
            name="phone"
            mask="(000) 000-0000"
            error={! phoneError}
            helperText={phoneError ? "" : "Please enter a valid 10 digit phone number" }
            onChange={props.handleInputChange}
            value={props.personValues?.phone || ''}
          />
          : <Skeleton variant="rectangular" width={"100%"}> <TextField/> </Skeleton> }
        </Grid>
        {props.editMode !== EditPersonType.selfEdit && <Grid item sm={12} md={4} lg={4} xl={4}>
          { props.isLoaded ?
          <TextField
            required
            disabled={isLockedMode || props.editMode !== EditPersonType.buyerAdd}
            fullWidth
            id="role"
            label="Role"
            name="role"
            onChange={props.handleInputChange}
            value={props.personValues?.role || ''}
          />
          : <Skeleton variant="rectangular" width={"100%"}> <TextField/> </Skeleton> }
        </Grid>}
        {props.editMode !== EditPersonType.selfEdit && <Grid item sm={12} md={4} lg={4} xl={4}>
          { props.isLoaded ?
          <Select
            required
            disabled={isLockedMode || props.editMode !== EditPersonType.buyerAdd}
            fullWidth
            id="permissionGroupId"
            label="Permission Group"
            name="permissionGroupId"
            onChange={props.handleSelectChange}
            value={props.personValues?.permissionGroupId ? props.personValues.permissionGroupId : permissionGroupId}
          >
            {
              distributablePermissionGroups.map(permissionGroup => {
                return <MenuItem key={permissionGroup.id} value={permissionGroup.id}>{permissionGroup.name}</MenuItem>
              }) 
            }
          </Select>
          : <Skeleton variant="rectangular" width={"100%"}> <TextField/> </Skeleton> }
        </Grid>}
        {props.editMode !== EditPersonType.buyerEdit && <Grid item sm={6} md={4} lg={3} key={"phoneOptIn"}>
          { props.isLoaded ?
          <FormControlLabel 
            control={
              <Switch
                disabled={isLockedMode || !props.personValues?.phone}
                id="phone-number"
                name="phoneOptIn"
                checked={props.personValues?.phoneOptIn && props.personValues?.phone !== ""} 
                onChange={props.handleInputChange}/>
            }
            label={<Typography variant="body2">Opt in to texts</Typography>} 
          />
          : <Skeleton variant="rectangular" width={"100%"}> <TextField/> </Skeleton> }
        </Grid>}
        {props.editMode !== EditPersonType.buyerEdit && <Grid item sm={6} md={4} lg={3} key={"emailOptIn"}>
          { props.isLoaded ?
          <FormControlLabel 
            control={
              <Switch
                disabled={isLockedMode}
                id="email"
                name="emailOptIn"
                checked={props.personValues?.emailOptIn && props.personValues?.email !== ""} 
                onChange={props.handleInputChange}/>
            }
            label={<Typography variant="body2">Opt in to emails</Typography>} 
          />
          : <Skeleton variant="rectangular" width={"100%"}> <TextField/> </Skeleton> }
        </Grid>}
        {props.editMode === EditPersonType.buyerAdd && <Grid item sm={6} md={4} lg={3} key={"hasAccount"}>
          { props.isLoaded ?
          <FormControlLabel 
            control={
              <Switch
                disabled={isLockedMode}
                id="hasAccount"
                name="hasAccount"
                checked={props.hasAccount} 
                onChange={props.handleAccountChecked}/>
            }
            label={<Typography variant="body2">Also invite via email?</Typography>} 
          />
          : <Skeleton variant="rectangular" width={"100%"}> <TextField/> </Skeleton> }
        </Grid>}
        { !isLockedMode && <Fragment>
          <Grid item xs={12}/>
          <Grid item xs={4}>
            <Button variant="outlined" onClick={() => setIsLockedMode(true)}>
              <Typography variant={'subtitle2'}>
                Cancel
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={8}>
            <Box display="flex" justifyContent="flex-end">
              <CircularProgressBackdrop open={isSubmitting} />
              <Button
                variant="contained"
                type="submit"
                disableElevation
                disabled={! phoneError || firstNameError || lastNameError}
              >
                <Typography variant={'subtitle2'}>
                  Save changes
                </Typography>
              </Button>
            </Box>
          </Grid>
        </Fragment>}
      </Grid>
    </Box>
  )
}